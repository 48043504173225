<template>
  <div class="medium-page">
    <!-- If we don't have the mentionTrackingGroup data just yet -->
    <template v-if="isLoading || !overview">
      <div
        class="mx-auto"
        style="max-width: 360px"
      >
        <lottie-animation
          loop
          key="antenna"
          file="loading-files-StOdYbIRa1.json"
        />
      </div>
    </template>

    <!-- Otherwise -->
    <template v-else>
      <!-- If we are still crawling the web -->
      <template v-if="overview.model.crawling_status !== 'completed'">
        <!-- The header buttons -->
        <div class="d-flex justify-space-between">
          <!-- Show the go back button -->
          <v-btn
            text
            color="primary"
            @click="$router.push({ name: 'MentionTrackingGroupIndex' })"
          >
            <v-icon left>
              arrow_back
            </v-icon>

            Reports
          </v-btn>
        </div>

        <!-- Show an animation first -->
        <div class="mx-auto" style="max-width: 360px">
          <lottie-animation
            loop
            key="shaking-box"
            :file="overview.model.crawling_status === 'failed' ? 'error-zYjmTFCcVN.json' : 'animation_llcesz69.json'"
          />
        </div>

        <!-- Add an empty element to fix the gap -->
        <div style="margin-top: -60px;"></div>

        <div class="text-center mt-2">
          {{ overview.model.crawling_status === "failed" ? "We could not prepare a report for this competition analysis, please contact us." : "It might take some minutes, you can leave the page and come back later." }}
        </div>
      </template>

      <!-- Otherwise, show the router-view child component -->
      <div
        v-else
        class="mention-tracking-group-child-view"
      >
        <router-view
          :key="reloadKey"
          :overview="overview"
          @reload="fetchDetails"
        />
      </div>
    </template>
  </div>
</template>

<script>
// Import children components
const LottieAnimation = () => import(/* webpackChunkName: "lottie-animation" */ "@/components/common/LottieAnimation.vue")

// Export the SFC
export default {
  // Name of the component
  name: "MentionTrackingGroupView",

  // Register children components
  components: {
    LottieAnimation,
  },

  // Define local data variables
  data: () => ({
    // If the data is being loaded
    isLoading: false,

    // The reload key to make sure the UI is always up-to-date
    reloadKey: null,
  }),

  // Define readonly data variables
  computed: {
    /**
     * Get the model data from Vuex store
     *
     * @returns {Object}
     */
    overview() {
      // Get the data from store
      return this.$store.getters["mentionTrackingGroup/detailByUuid"](this.$route.params.uuid)
    },
  },

  // Define local method functions
  methods: {
    /**
     * When called, fetch and update the campaign data in Vuex store
     *
     * @param {Object} options
     * @returns {void}
     */
    async fetchDetails() {
      // Show a loader
      const loaderId = Symbol()
      this.$store.dispatch("loaders/add", loaderId)
      this.isLoading = true

      // Also, reload the children view
      this.reloadKey = Symbol()

      // Wait for the load to be completed
      await this.$store.dispatch("mentionTrackingGroup/fetchDetails", {
        uuid: this.$route.params.uuid
      })

      // Hide the loaders
      this.$store.dispatch("loaders/remove", loaderId)
      this.isLoading = false
    },
  },

  /**
   * As soon as the component data has been created
   *
   * @returns {void}
   */
  created() {
    // Dispatch a request to create a network request
    this.fetchDetails()
  },
}
</script>